* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Sizing the map container */
.leaflet-container {
  height: 85vh;
  width: 90vw;
}

.cluster-icon {
  height: 2em;
  width: 2em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  box-shadow: 0 0 0px 5px #fff;
}

.alert-popup {
  bottom: 18px !important;
}