body {
  margin: 0;
  font-family: 'fagoco', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #001B48;
}

@font-face {
  font-family: 'fagoco';
  src: url('./config/fonts/FagoCo.otf') format('truetype');
}

.toast-error{
  background: #e74c3c;
  z-index: 9999;
  --toastify-icon-color-error: white;
  --toastify-color-progress-error: white;
}
.toast-warning{
  background: #f1c40f;
  z-index: 9999;
  --toastify-icon-color-warning: white;
  --toastify-color-progress-warning: white;
}
.toast-info{
  background: #3498db;
  z-index: 9999;
  --toastify-icon-color-info: white;
  --toastify-color-progress-info: white;
}
.toast-success{
  background: #07bc0c;
  z-index: 9999;
  --toastify-icon-color-success: white;
  --toastify-color-progress-success: white;
}


table, th, td {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0.4%;
}
table {
  border-collapse: separate;
  border-spacing: 0; 
  border-radius: 10px; 
  overflow: hidden;
  margin-top: 1%;
}
th{
  background-color: #e2dad5;
}
tr.channel_row:nth-child(even) {
  background-color: #E7E1DD;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-row {
  display: flex;
  align-items: center;
  gap: 0.5rem; 
  width: 100%;
  justify-content: center;
}

.error-message {
  margin-top: 0.2rem;
  color: #e74c3c; 
  font-size: 0.9rem;
  text-align: left;
  width: 100%;
}
.custom-popup .leaflet-popup-tip {
  display: none;
}

.custom-popup .leaflet-popup-content-wrapper {
  position: relative;
  border-radius: 5px;
}

/* Punta por defecto (arriba) */
.custom-popup::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
}

/* Punta cuando está en el borde superior (abajo) */
.custom-popup.top-edge .leaflet-popup-content-wrapper::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: white transparent transparent transparent;
}

/* Ocultar la punta superior cuando está en modo top-edge */
.custom-popup.top-edge::after {
  display: none;
}
.moving-average-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 3rem; /* Establece una altura mínima */
}
.moving-average-hidden {
  visibility: hidden; /* Oculta los campos pero conserva el espacio */
}
.moving-average-visible {
  visibility: visible;
}